import "./App.css";
import { useCoolMode } from "./coolMode";

function App() {
  const emojiRef = useCoolMode(["💎", "🏎️", "📱", "⚡️", "💻"]);

  return (
    <div className="App" ref={emojiRef}>
      <div className="headline">Yes</div>
      <div className="tagline">It's your computer that is slow</div>
    </div>
  );
}

export default App;
